@import "variables";

.journal {
  display: grid;
  grid-template-columns: 0.65fr 1fr;
  grid-gap: 24px;

  .journalItem {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    padding: 24px;
    box-sizing: border-box;
    min-height: 350px;
    border: 1px solid #e8ebef;

    .journalItemBackground {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.4);
    }

    .journalItemContent {
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 2;
      color: white;

      .journalItemTitle {
        font-family: $font-title;
        font-weight: 600;
        font-size: 32px;
      }

      .journalItemDescription {
        margin-top: auto;
      }

      .journalItemFooter {
        margin-top: auto;
        display: flex;
        align-items: flex-end;
      }

      .journalItemReadMore {
        display: flex;
        align-items: center;
        margin-left: auto;

        span {
          font-size: 21px;
          margin-right: 8px;
          transition: all 200ms;

          a {
            color: white;
          }
        }
      }
    }
  }

  .journalGroup {
    display: flex;
    flex-direction: column;

    .journalGroupItem {
      color: inherit;

      .journalItemImage {
        width: 100px;
        height: 60px;
        border-radius: 8px;
        object-fit: cover;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 0.35fr 1fr;
      }

      &:hover {
        color: $main-color-light;
        //cursor: pointer;
      }

      &:active {
        /*color: $main-color;*/
      }


      .journalItemContent {
        font-size: 16px;
        color: $text-color;
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span {
          font-weight: 600;
          color: #0b7a94;
          cursor: pointer;
        }

        .journalItemFooter {
          display: flex;

          .journalItemCategories {
            font-size: 12px;
            margin-right: auto;
          }

          .journalItemDate {
            font-size: 12px;
            margin-left: auto;
          }
        }
      }
    }

    .journalGroupAction {
      display: flex;
      justify-content: center;
      margin-top: auto;
    }
  }

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column-reverse;

    .journalGroup {
      margin-top: 16px;
    }
  }
}