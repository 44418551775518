.block {
  @media (max-width: 992px) {
    display: none;
  }
}

.widget {
  height: 440px;

  @media (max-width: 992px) {
    height: 300px;
    margin: -12px;
  }
  @media (max-width: 768px) {
    height: 260px;
  }
  @media (max-width: 576px) {
    height: 180px;
  }

  .widgetImage {
    width: 100%;
    height: 100%;
    position: relative;
    //border-radius: 8px;
    overflow: hidden;
    background-color: #e8ebef;

    @media (max-width: 992px) {
      border-radius: 0;
    }
  }
  .widgetSearch {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: max-content;
    margin: auto;

    .widgetSearchSlogan {
      display: flex;
      position: relative;
      font-size: 51px;
      font-weight: 800;
      color: white;
      text-shadow: 0 0 12px rgba(0, 0, 0, 0.3);

      span {
        @media (min-width: 992px) {
          margin-left: 12px;
        }
      }
      @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
        font-size: 45px;
      }
      @media (max-width: 768px) {
        font-size: 38px;
      }
    }
  }
}