@import "variables";
@import "mixins";

.search {
  margin-top: 18px;
  display: none;

  @media (min-width: 992px) {
    display: block;
  }

  .searchTabs {
    display: flex;
    border-radius: 8px;
    overflow: auto;
    max-width: max-content;
    background-color: rgba(40, 40, 40, 0.5);
    box-shadow: $shadow;

    .searchTabsSplitter {
      width: 1px;
      background-color: rgba(255, 255, 255, 0.6);
      margin: 8px 0;
      flex-shrink: 0;
    }
    .searchTabsButton {
      color: #FFFFFF;
      outline: none;
      flex: 1;
      position: relative;
      font-weight: 300;
      cursor: pointer;
      background: none;
      margin: 0;
      padding: 0 24px;
      margin: 4px;
      border-radius: 6px;
      height: 50px;
      border: 1px solid transparent;
      line-height: 1;
      transition: all 200ms;

      &:not(.disabled):hover {
        background-color: rgba(0, 0, 0, 0.4);
      }
      &:not(.disabled):active {
        transform: scale(0.95);
        background-color: #C44358 !important;
      }
      &:not(.disabled):focus {
        font-weight: 600;
        background-color: rgba(0, 0, 0, 0.6);
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.2;
      }
      &.active {
        color: white !important;
        font-weight: 600;
        background-color: $main-color-light !important;
      }
    }
  }
  .searchFilters {
    display: flex;
    background-color: white;
    box-shadow: $shadow;
    margin-top: 4px;
    border-radius: 8px;
    height: 66px;

    .searchFiltersSplitter {
      width: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      margin: 8px 0;
      flex-shrink: 0;

      @media (max-width: 992px) {
        width: auto;
        height: 1px;
        margin: 0 8px;
      }
    }
  }
}