.commercial {
  display: none;
  align-items: center;
  position: relative;
  margin-top: 12px;
  background-color: #ffde5a;
  padding: 14px 12px 16px 18px;
  border-radius: 8px;
  text-align: center;
  width: max-content;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0 0 12px rgba(255, 222, 90, 0.7);

  .commercialIcon {
    margin-left: 8px;
    color: #af921d;
    display: flex;
    animation: pulse 1s linear infinite alternate;
  }

  @media (min-width: 992px) {
    display: flex;
  }
}

@keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.5;
  }
}