@import "variables";

.sl {
  flex: 1 1 auto;
  min-width: 0;
  margin-bottom: 12px;

  .slTitle {
    display: block;
    font-family: $font-title;
    color: $text-color;
    font-size: 14px;
    user-select: none;
    margin-bottom: 6px;
    text-align: left;
    white-space: nowrap;
    width: max-content;
  }
  .slContent {
    position: relative;

    .slView {
      display: flex;
      flex-direction: row;

      .slSelect {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 12px 14px;
        box-sizing: border-box;
        min-height: 50px;
        min-width: 250px;
        border-radius: 8px;
        background-color: #f9fafb;
        cursor: pointer;
        outline: none;
        flex-shrink: 0;
        color: $text-color-dark;
        border: 1px solid #e8ebef;
        font-weight: 500;
        line-height: 1;
        transition: all 200ms;
        white-space: nowrap;
        user-select: none;
        flex: 1;
  
        .slSelectPlaceholder {
          font-weight: 300;
          color: $text-color-light;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 36px;
        }
        .slSelectLabels {
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 36px;
        }

        &:focus {
          background-color: $background-button-hover;
          border-color: $main-color-light;
          color: $main-color-light;
        }
    
        &.active {
          background-color: $background-button-hover;
          border-color: $main-color-light;
        }
      }
      .slSelectHidden {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: calc(100% - 50px);
        opacity: 0;
      }
      .slReset {
        position: absolute;
        top: 6px;
        right: 6px;
        cursor: pointer;
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        border: 1px solid transparent;
        color: $text-color;
        padding: 0;
        flex-shrink: 0;
        margin-left: 2px;
        border-radius: 8px;
        transition: all 200ms;
        outline: none;

        &:hover {
          background-color: #E8505B;
          border-color: #d36464;
          color: white;
        }
        &:focus {
          background-color: #d36464;
          border-color: #c45e5e;
          color: white;
        }
        &:active {
          transform: scale(0.95);
        }
      }
    }

    .slDropdown {
      position: absolute;
      top: calc(100% + 2px);
      z-index: 10;
      background-color: #f9fafb;
      color: $text-color-dark;
      border: 1px solid #e8ebef;
      border-radius: 8px;
      padding: 2px;
      left: 0;
      right: 0;
      max-height: 320px;
      overflow: auto;
      overscroll-behavior-y: contain;

      .slDropdownSearch {
        position: sticky;
        top: -2px;
        margin: -2px -2px 0px -2px;
        padding: 2px;
        background-color: white;

        input {
          outline: none;
          border: none;
          width: 100%;
          box-sizing: border-box;
          height: 50px;
          padding: 12px;
          background-color: white;
          border: 1px solid #e8ebef;
          border-radius: 6px;

          &::placeholder {
            font-weight: 300;
            color: $text-color-light;
          }
        }
      }
      .slDropdownItem {
        user-select: none;
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 50px;
        border-radius: 6px;
        background-color: #f9fafb;
        border: 1px solid transparent;
        padding: 0;
        margin: 0;
        font-weight: 500;
        line-height: 1;
        transition: all 200ms;
        outline: none;

        &:not(:first-child) {
          margin-top: 2px;
        }

        .slDropdownItemCheckbox {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          flex-shrink: 0;

          div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            width: 28px;
            border-radius: 6px;
            background-color: #e8ebef;
          }
        }
        .slDropdownItemText {
          display: flex;
          align-items: center;
          flex-shrink: 0;
        }

        &:not(.disabled):hover {
          background-color: $background-button-hover;
          border-color: $main-color-light;
          color: $main-color-light;
        }
        &:not(.disabled):active {
          border-color: $main-color-light;
          background-color: $background-button-active;
        }
        &:not(.disabled):focus {
          border-color: $main-color-light;
        }

        &.active {
          color: $main-color-light;

          .slDropdownItemCheckbox {
            div {
              background-color: $main-color-light;
              color: white;
            }
          }
        }
        &.big {
          font-size: 18px;
        }
        &.group {
          padding-left: 18px;
        }
        &.disabled {
          color: $text-color-light;
          cursor: not-allowed;
        }
      }
    }
  }
}