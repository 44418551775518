@import "variables";

.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  margin: 4px;
  overflow: auto;
  color: inherit;

  .checkboxButton {
    color: inherit;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 6px;
    padding: 0 12px;
    height: 100%;
    min-width: 50px;
    font-weight: 500;
    background-color: white;
    line-height: 1;
    transition: all 200ms;

    &:hover {
      color: $main-color-light;
      border-color: $main-color-light;
      background-color: $background-button-hover;
    }

    &:active {
      transform: scale(0.95);
    }

    &:focus {
      color: $main-color-light;
      background-color: $background-button-active;
      border-color: $main-color-light;
    }

    &.active {
      color: white;
      background-color: $main-color-light;
    }

    @media (max-width: 992px) {
      width: 100%;
      min-width: 80px;
    }
  }

  .checkboxSplitter {
    width: 1px;
    height: 24px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 4px;
    flex-shrink: 0;
  }

  @media (max-width: 992px) {
    justify-content: flex-start;
  }
}