@import "variables";

.select {
  position: relative;
  min-width: 135px;
  height: 56px;
  margin: 4px;

  .selectView {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: white;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    padding: 0 38px 0 12px;
    height: 100%;
    width: 100%;
    font-weight: 500;
    transition: all 200ms;
    color: inherit;
    line-height: 1;

    &:hover {
      color: $main-color-light;
      border-color: $main-color-light;
      background-color: $background-button-hover;
    }
    &:active {
      transform: scale(0.95);
    }
    &:focus {
      color: $main-color-light;
      background-color: $background-button-active;
      border-color: $main-color-light;
    }

    .selectViewHidden {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      opacity: 0;
    }
  }
  .selectDropdown {
    position: absolute;
    top: calc(100% + 10px);
    left: -4px;
    min-width: 100%;
    border-radius: 8px;
    padding: 4px;
    background-color: white;
    box-shadow: $shadow;
    z-index: 10;
    max-height: 320px;
    overflow: auto;

    .selectDropdownItem {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      border: 1px solid transparent;
      box-sizing: border-box;
      height: 48px;
      width: 100%;
      border-radius: 6px;
      background-color: white;
      padding: 12px;
      white-space: nowrap;
      font-weight: 500;
      line-height: 1;
      transition: all 200ms;

      &:not(:first-child) {
        margin-top: 2px;
      }

      &:hover {
        color: $main-color-light;
        border-color: $main-color-light;
        background-color: rgba(41, 105, 224, 0.1);
      }
      &:active {
        transform: scale(0.95);
      }
      &:focus {
        color: $main-color-light;
        background-color: rgba(41, 105, 224, 0.2);
        border-color: $main-color-light;
      }

      &.active {
        color: white;
        background-color: $main-color-light;
      }
    }
  }
}