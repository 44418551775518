@import "variables";

.cb {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 12px;

  &.inline {
    flex-direction: row;
  }

  .cbTitle {
    display: block;
    font-family: $font-title;
    color: $text-color;
    font-size: 14px;
    user-select: none;
    margin-bottom: 6px;
    text-align: left;
    white-space: nowrap;
  }
  .cbContent {
    display: flex;
    flex-wrap: wrap;
    margin: -4px 0 0 -4px;

    .cbChekbox {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 12px 14px;
      box-sizing: border-box;
      min-height: 50px;
      border-radius: 8px;
      font-weight: 500;
      line-height: 1;
      background-color: #f9fafb;
      cursor: pointer;
      outline: none;
      color: $text-color-dark;
      border: 1px solid #e8ebef;
      transition: all 200ms;
      white-space: nowrap;
      user-select: none;
      margin: 4px 0 0 4px;

      &:not(.disabled):hover {
        background-color: $background-button-hover;
        border-color: $main-color-light;
        color: $main-color-light;
      }
      &:not(.disabled):focus {
        background-color: $background-button-hover;
        border-color: $main-color-light;
        color: $main-color-light;
      }
      &:not(.disabled):active {
        background-color: $background-button-active;
        transform: scale(0.95);
      }

      &.active {
        background-color: $main-color-light !important;
        border-color: $main-color-light !important;
        color: white !important;
      }
      &.disabled {
        color: $text-color-light;
        cursor: not-allowed;
        background: none;
        border-color: #f7fbff;
      }
    }
  }
}