@import "variables";
@import "mixins";

.tools_regions {
  margin-top: 12px;

  .toolsItem {
    position: relative;
    overflow: hidden;
    flex: 1;
    padding: 24px;
    background-color: white;
    border: 1px solid #e8ebef;
    border-radius: 12px;

    @media (max-width: 992px) {
      padding: 18px;
    }

    .toolsItemRegions {
      column-count: 3;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      max-height: 192px;
      overflow: hidden;

      &::after {
        background-image: linear-gradient(180deg, #fff0, #fff 30%);
        bottom: 0;
        content: "";
        height: 110px;
        left: 0;
        position: absolute;
        right: 0;
      }

      &.active {
        max-height: fit-content;
        margin-bottom: 25px;
      }

      @media (max-width: 992px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
    }

    .toolsItemTitle,
    .toolsItemSubtitle,
    .toolsItemButton {
      position: relative;
      z-index: 5;
    }

    .toolsItemTitle {
      font-family: $font-title;
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    a {
      width: max-content;
      transition: color 200ms;
      display: block;
      margin-top: 6px;
      color: $main-color-dark;

      &:hover,
      &:active {
        color: $main-color-light;
      }
    }
  }
}

.tools {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 12px;
  grid-template-areas: "bf rf h nb"
    "bb rb m m";

  .toolsItem {
    position: relative;
    overflow: hidden;
    flex: 1;
    padding: 24px;
    background-color: white;
    border: 1px solid #e8ebef;
    border-radius: 12px;

    @media (max-width: 992px) {
      padding: 18px;
    }

    &:nth-child(1) {
      grid-area: bf;
    }

    &:nth-child(2) {
      grid-area: rf;
    }

    &:nth-child(3) {
      grid-area: h;
    }

    &:nth-child(4) {
      grid-area: nb;
    }

    &:nth-child(5) {
      grid-area: bb;
    }

    &:nth-child(6) {
      grid-area: rb;
    }

    &:nth-child(7) {
      grid-area: m;
    }

    .toolsItemTitle,
    .toolsItemSubtitle,
    .toolsItemButton {
      position: relative;
      z-index: 5;
    }

    .toolsItemTitle {
      font-family: $font-title;
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .toolsItemSubtitle {
      opacity: 0.65;
    }

    .toolsItemButton {
      @include button-blue;

      height: 49px;
      width: max-content;
      margin-top: 24px !important;
      padding: 12px 24px;
    }

    a {
      width: max-content;
      transition: color 200ms;
      display: block;
      color: $main-color-dark;

      &:hover,
      &:active {
        color: $main-color-light;
      }

      &:not(:first-child) {
        margin-top: 8px;
      }
    }

    .searchByMap {
      display: flex;
      flex-direction: row-reverse;

      .usefoolMap {
        position: absolute;
        width: 260px;
        left: 10px;
      }

      @media (max-width: 992px) {
        flex-direction: column-reverse;

        .usefoolMap {
          position: relative;
        }
      }
    }
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    grid-template-areas: "bf rf"
      "h nb"
      "bb rb"
      "m";
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-template-areas: "bf"
      "rf"
      "h"
      "nb"
      "bb"
      "rb"
      "m";
  }
}