@import "variables";

.input {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  margin: 4px;

  input {
    height: 100%;
    background-color: white;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 6px;
    width: 130px;
    text-align: center;
    outline: none;
    line-height: 1;
    font-weight: 500;

    &::placeholder {
      font-weight: 300;
      color: $text-color-light;
    }

    @media (max-width: 992px) {
      width: 100%;
    }
  }
  .inputSplitter {
    width: 1px;
    height: 24px;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 4px;
    flex-shrink: 0;
  }
}