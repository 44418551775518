@import "variables";

.block {
  background: #f3f4f6;
  border-radius: 0 0 12px 12px;
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
}
.group {
  display: flex;
  margin: -12px -12px 0 -12px;
  overflow: auto;

  .groupButton {
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 108px;
    min-width: 120px;
    border-radius: 8px;
    margin: 0 0 12px 12px;
    color: inherit;
    border: 1px solid #e8ebef;
    transition: all 200ms;
    background: none;

    &:hover {
      box-shadow: $shadow;
    }
    &:active {
      transform: scale(0.95);
    }
    &:focus {
      border-color: $main-color-light;
    }

    span {
      font-family: $font-title;
      font-weight: 500;
      margin-top: 12px;
    }
  }
  .groupButtonFake {
    width: 12px;
    flex-shrink: 0;
  }
}
.actions {
  display: flex;

  & button:not(:last-child) {
    margin-right: 8px;
  }

  @media (max-width: 400px) {
    flex-direction: column;

    & button:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
.description {
  margin-bottom: 24px;
  text-align: center;
  color: $text-color;
  font-weight: 500;
}